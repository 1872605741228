






























































import { OtherModule } from "@/store/other";
import { Vue, Component } from "vue-property-decorator";
@Component
export default class extends Vue {

  to_report() :void{
    this.$router.push('/customer/report')
  }

  get is_mobile(): boolean {
    return OtherModule.is_mobile;
  }
}
