





















































































































































































































































































/* eslint-disable */
import EditPass from "@/views/user/component/editpass.vue";
import { UserModule } from "@/store/user";
import { OtherModule } from "@/store/other";
import { Vue, Component, Watch } from "vue-property-decorator";
import { api_home, position } from "@/api";
import StorageDb from "@/util/storage";
@Component({
  components: {
    EditPass,
  },
})
export default class extends Vue {
  key = false;

  input = "";

  tab = [
    {
      name: "응시직렬",
      index: "/exam",
      children: [],
    },
    {
      name: "그룹스터디",
      index: "/group",
      children: [
        {
          name: "그룹방",
          index: "/group/team",
        },
        {
          name: "랜덤방",
          index: "/group/random",
        },
      ],
    },
  ];

  active: "customer" | "" | "user" = "";

  is_active(type: boolean, active: "customer" | "user") {
    if (type) {
      this.active = active;
    } else {
      this.active = "";
    }
  }

  get is_login() {
    return UserModule.token !== "" && UserModule.token !== null;
  }

  customer(link: string) {
    this.$router.push(link);
  }

  async user(type: string) {
    if (type === "logout") {
      await this.$confirm('정말 로그아웃 하시겠습니까?', '알림', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
      })
      UserModule.logout();
      this.$message.success("로그아웃 되었습니다.");
      this.$router.push("/");
    } else if (type === "edit_pass") {
      (this.$refs["edit_pass"] as any).key = true;
    } else {
      this.$router.push(type);
    }
    this.active = "";
  }

  get defaultActive() {
    if (/^\/active/.test(this.$route.path as string)) {
      return `/active/1/list`;
    }
    if (/^\/club/.test(this.$route.path as string)) {
      return this.$route.path;
    }
    return (
      "/" + (this.$route.path as string).split("/").filter((x) => x !== "")[0]
    );
  }

  @Watch("$route")
  watch_route() {
    this.key = false;
  }

  search() {
    const search = this.input;
    this.$router.push(`/subject/0/list/?search=${search}`);
    this.input = ''
  }

  get avatar() {
    return UserModule.info?.avatar;
  }

  get is_mobile() {
    return OtherModule.is_mobile;
  }

  phone_click() {
    if (this.$route.name === "index") {
      history.go(0);
    } else {
      this.$router.push("/");
    }
  }

  async get_position_list() {
    const res = await api_home.get_position({ page: 1, page_size:100 });
    this.tab[0].children = res.results.map((item) => {
      return {
        name: item.title,
        index: `/subject/${item.id}/`,
      };
    });
    StorageDb.setLocal(
      "position",
      res.results.map((item) => {
        return {
          label: item.title,
          value: item.id,
        };
      })
    );
  }

  get is_show(){
    return UserModule.info?.is_show
  }

  created() {
    this.get_position_list();
  }
}
