


















import { Vue, Component, Watch } from "vue-property-decorator";
import Header from "./header.vue";
import Rview from "@/components/routerView/index.vue";
import Footer from "./footer.vue";
import { OtherModule } from "@/store/other";
@Component({
    components: {
        Header,
        Rview,
        Footer,
    },
})
export default class extends Vue {
    /* eslint-disable */
	@Watch('$route')
	watch():void{
        // eslint-disable-next-line
		(this.$refs['scrollbar'] as any).wrap.scrollTop = 0 
	}

    get is_mobile() {
    return OtherModule.is_mobile;
  }
}
