











































/* eslint-disable */ 
import { Vue, Component } from "vue-property-decorator";
import Rview from "@/components/routerView/index.vue";
import Bread from "@/components/bread/index.vue";
// import { UserModule } from "@/store/user";
import { api_user } from "@/api";
import { ElForm } from "element-ui/types/form";
import { mapObjIndexed } from "ramda";
@Component({
    components: {
        Rview,
        Bread,
    },
})
export default class extends Vue {

	form = {
		old_password:'',
		new_password1:'',
		new_password2:''
	}
	rules = {
		old_password:[
            {
				validator: this.validatePass_8,
                required: true,
                trigger: ["change"],
            },
        ],
		new_password1: [
            {
				validator: this.validatePass_8,
                required: true,
                trigger: ["change"],
            },
        ],
        new_password2: [
            {
                required: true,
                validator: this.validatePass,
                trigger: ["change"],
            },
        ],
	}
	key = false
	async submit(){
		await (this.$refs["form"] as ElForm).validate();
		if(this.form.old_password === this.form.new_password1){
			return this.$message.error('기존 비밀번호와 일치한 번호입니다.')
		}
		this._loading = true
		await api_user.edit_pass(this.form).finally(()=>{
			this._loading = false
		})
		this.close()
		this.$message.success('수정 되었습니다.')
		this.form = mapObjIndexed()(this.form) as {
			old_password: string;
			new_password1: string;
			new_password2: string;
		}
	}

	async close(){
		await (this.$refs['form'] as ElForm).resetFields()
		this.key = false;
	}

	validatePass_8(rules:any, value:any, callback:any){
		const reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/
		if(reg.test(value)){
			(this.$refs["form"] as ElForm).validateField("again_pass")
			callback()
		}else{
			callback(new Error("영문, 숫자, 특수문자(! 또는 @) 포함 8자 이상 입력"));
		}
	}

    validatePass(rules:any, value:any, callback:any) {
        if (this.form.new_password1 !== this.form.new_password2) {
            callback(new Error("비밀번호와 비밀번호 확인이 일치하지 않습니다."));
        } else {
            callback();
        }
	}
}
